import React, { useState, useEffect } from "react";
import { getEditor } from "./EditorRegistry";
import { PDFViewer } from "@react-pdf/renderer";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import { useSelector, useDispatch } from "react-redux";
import { updateProposalData } from "../../store";
import { updateProposal } from "../../services/proposalService";
import { usePrompt } from "./usePrompt";
import Navbar from "../Navbar/Navbar";
import cloneDeep from "lodash/cloneDeep";
import structuredClone from "@ungap/structured-clone";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  DialogContentText,
  Alert, // for a nicer snackbar
} from "@mui/material";
import {
  CheckCircle,
  Add as AddIcon,
  Settings as SettingsIcon,
  Description as FileTextIcon,
  AttachMoney as DollarSignIcon,
  Build as HammerIcon,
  Balance as ScaleIcon,
  People as UsersIcon,
  Event as CalendarIcon,
  Message as MessageSquareIcon,
  DragIndicator as GripVerticalIcon,
  Visibility as EyeIcon,
  VisibilityOff as EyeOffIcon,
  Error as AlertCircleIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

/**
 * Helper to build the JSON structure you'll store in proposal_data.ordering.
 */
const buildProposalJson = ({
  sectionsState,
  sectionOrder,
  itemOrder,
  selectedItems,
}) => {
  // Create a new sectionsState with string-based icons
  const serializedSectionsState = Object.entries(sectionsState).reduce(
    (acc, [key, section]) => ({
      ...acc,
      [key]: {
        ...section,
        icon: SECTION_ICON_MAP[key], // Store the icon identifier string
      },
    }),
    {}
  );

  return {
    sectionsState: serializedSectionsState,
    sectionOrder,
    itemOrder,
    selectedItems,
  };
};

const ICON_MAP = {
  users: UsersIcon,
  settings: SettingsIcon,
  fileText: FileTextIcon,
  dollar: DollarSignIcon,
  hammer: HammerIcon,
  scale: ScaleIcon,
  message: MessageSquareIcon,
};

// Section to icon name mapping
const SECTION_ICON_MAP = {
  company: "users",
  scope: "hammer",
  technical: "settings",
  financial: "dollar",
  legal: "scale",
  service: "message",
};

const ProposalBuilderInterface = () => {
  const dispatch = useDispatch();

  // ---- Retrieve from Redux ----
  const proposalId = useSelector((state) => state.takeoffData.proposalId);
  const proposal_data = useSelector((state) => state.proposalData.data);

  // ---- Extract any previously saved ordering from proposal_data ----
  const {
    sectionsState: savedSectionsState,
    sectionOrder: savedSectionOrder,
    itemOrder: savedItemOrder,
    selectedItems: savedSelectedItems,
  } = proposal_data.ordering || {};

  // Helper to get icon component
  const getIconComponent = (sectionKey) => {
    const iconName = SECTION_ICON_MAP[sectionKey];
    return ICON_MAP[iconName] || SettingsIcon;
  };

  // ---- Default sectionsState if none was saved yet ----
  const defaultSectionsState = {
    company: {
      icon: "users",
      title: "Company Information",
      items: [
        {
          id: "overview",
          title: "Company Overview",
          description: "Background, history, and achievements",
        },
        {
          id: "license",
          title: "License & Insurance",
          description: "Certifications and coverage details",
        },
        {
          id: "portfolio",
          title: "Portfolio",
          description: "Past projects and testimonials",
        },
      ],
    },
    scope: {
      icon: "hammer",
      title: "Project Scope",
      items: [
        {
          id: "overview",
          title: "Project Overview",
          description: "Description and objectives",
        },
        {
          id: "timeline",
          title: "Construction Timeline",
          description: "Schedule and milestones",
        },
        {
          id: "materials",
          title: "Materials & Finishes",
          description: "Specifications and selections",
        },
      ],
    },
    technical: {
      icon: "settings",
      title: "Technical Specifications",
      items: [
        {
          id: "structural",
          title: "Structural Details",
          description: "Foundation and framing specs",
        },
        {
          id: "systems",
          title: "Building Systems",
          description: "HVAC, electrical, and plumbing",
        },
        {
          id: "sustainability",
          title: "Energy Efficiency",
          description: "Green building features",
        },
      ],
    },
    financial: {
      icon: "dollar",
      title: "Financial Details",
      items: [
        {
          id: "costs",
          title: "Cost Breakdown",
          description: "Detailed pricing structure",
        },
        {
          id: "payment",
          title: "Payment Schedule",
          description: "Timeline and terms",
        },
        {
          id: "allowances",
          title: "Allowances",
          description: "Budget allocations",
        },
      ],
    },
    legal: {
      icon: "scale",
      title: "Legal & Compliance",
      items: [
        {
          id: "contract",
          title: "Contract Terms",
          description: "Agreement details",
        },
        {
          id: "warranty",
          title: "Warranty Information",
          description: "Coverage and terms",
        },
        {
          id: "permits",
          title: "Permits & Approvals",
          description: "Required documentation",
        },
      ],
    },
    service: {
      icon: "message",
      title: "Customer Service",
      items: [
        {
          id: "communication",
          title: "Communication Plan",
          description: "Contact protocols",
        },
        {
          id: "support",
          title: "Support Services",
          description: "During and after construction",
        },
        {
          id: "quality",
          title: "Quality Assurance",
          description: "Standards and inspections",
        },
      ],
    },
  };

  // ---- Default order of sections (top-level) if none was saved ----
  const defaultSectionOrder = Object.keys(defaultSectionsState);

  // ---- Default item order for all sections if none was saved ----
  const buildDefaultItemOrder = () => {
    const arr = [];
    defaultSectionOrder.forEach((sectionKey) => {
      defaultSectionsState[sectionKey].items.forEach((item) => {
        arr.push({ sectionId: sectionKey, itemId: item.id });
      });
    });
    return arr;
  };

  // ---- Local state initialization ----
  const [sectionsState, setSectionsState] = useState(() => {
    if (savedSectionsState) {
      // Convert saved state to proper format
      return Object.entries(savedSectionsState).reduce(
        (acc, [key, section]) => ({
          ...acc,
          [key]: {
            ...section,
            icon: SECTION_ICON_MAP[key], // Use the mapped icon identifier
          },
        }),
        {}
      );
    }
    return defaultSectionsState;
  });
  const [sectionOrder, setSectionOrder] = useState(
    savedSectionOrder || defaultSectionOrder
  );
  const [itemOrder, setItemOrder] = useState(
    savedItemOrder || buildDefaultItemOrder()
  );
  const [selectedItems, setSelectedItems] = useState(savedSelectedItems || {});

  // ---- Expand/Collapse Section logic ----
  const [expandedSections, setExpandedSections] = useState({
    company: false,
    scope: false,
    technical: false,
    financial: false,
    legal: false,
    service: false,
  });

  // --- Track if there are unsaved changes ---
  const [isDirty, setIsDirty] = useState(false);
  const { showDialog, confirmNavigation, cancelNavigation, message } =
    usePrompt(
      "You have unsaved changes. Are you sure you want to leave?",
      isDirty
    );

  // --- Warn on tab close/refresh if isDirty is true ---
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        // Most browsers ignore custom messages here,
        // but setting returnValue is necessary for Chrome/Firefox.
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  // --- Snackbar (toast) for successful save ---
  const [showToast, setShowToast] = useState(false);

  const handleSaveDraft = async () => {
    try {
      // Build the current state of the proposal
      const currentProposalData = structuredClone({
        ...proposal_data,
        ordering: buildProposalJson({
          sectionsState, // Already deeply cloned inside the function
          sectionOrder,
          itemOrder,
          selectedItems,
        }),
      });

      // Update Redux Store Safely
      dispatch(updateProposalData(currentProposalData));

      // Persist to backend
      await updateProposal({
        id: proposalId,
        proposal_data: currentProposalData,
      });
      // Show success toast
      setShowToast(true);
      // Reset the isDirty state since we've persisted everything
      setIsDirty(false);
    } catch (error) {
      console.error("Error saving draft:", error);
      // Handle error appropriately
    }
  };

  // ---- Dragging states ----
  const [draggedItem, setDraggedItem] = useState(null); // For a SECTION
  const [draggedSubItem, setDraggedSubItem] = useState(null); // For an ITEM

  // ---- PDF Preview toggle, dialog states, etc. ----
  const [showPreview, setShowPreview] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  // ---- PDF Page Styles ----
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      backgroundColor: "white",
    },
    section: {
      marginBottom: 20,
    },
  });

  // ---- PDF Document for Preview ----
  const PDFDocument = ({
    itemOrder,
    selectedItems,
    sectionsState,
    getEditor,
    sectionOrder,
  }) => {
    // Group items by section
    const itemsBySection = itemOrder.reduce((acc, item) => {
      if (!acc[item.sectionId]) {
        acc[item.sectionId] = [];
      }
      acc[item.sectionId].push(item);
      return acc;
    }, {});

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Iterate through sections in the correct order */}
          {sectionOrder.map((sectionId) => {
            const sectionItems = itemsBySection[sectionId] || [];

            return sectionItems.map(({ itemId }) => {
              // If item not checked, skip
              if (!selectedItems[`${sectionId}-${itemId}`]) return null;

              const section = sectionsState[sectionId];
              if (!section) return null;

              const item = section.items.find((i) => i.id === itemId);
              const editorData = getEditor(sectionId, itemId, proposal_data);
              if (!editorData || !item) return null;

              const PDFContent = editorData.pdfContent;
              const data = editorData.data;

              return (
                PDFContent && (
                  <View key={`${sectionId}-${itemId}`} style={styles.section}>
                    <PDFContent {...(data || {})} />
                  </View>
                )
              );
            });
          })}
        </Page>
      </Document>
    );
  };

  // ---- Dialog Logic ----
  const handleOpenDialog = (sectionId, itemId) => {
    setSelectedSection({ sectionId, itemId });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSection(null);
  };

  // ---- Expand/Collapse a section ----
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // ---- DRAG & DROP HANDLERS ----
  const handleDragStart = (e, sectionId, itemId = null) => {
    // Mark data as dirty because user is reordering
    setIsDirty(true);

    if (itemId) {
      setDraggedSubItem({ sectionId, itemId });
      e.stopPropagation(); // so parent section drag won't trigger
    } else {
      setDraggedItem(sectionId);
    }
  };

  const handleDragOver = (e, sectionId, itemId = null) => {
    e.preventDefault();

    // ITEM Drag
    if (draggedSubItem && itemId) {
      if (draggedSubItem.itemId === itemId) return;

      const newSectionsState = { ...sectionsState };
      const draggedSection = draggedSubItem.sectionId;
      const draggedItemObj = newSectionsState[draggedSection].items.find(
        (it) => it.id === draggedSubItem.itemId
      );

      // Reorder the global itemOrder array
      const newItemOrder = [...itemOrder];
      const fromIndex = newItemOrder.findIndex(
        (io) =>
          io.sectionId === draggedSection && io.itemId === draggedSubItem.itemId
      );
      const toIndex = newItemOrder.findIndex(
        (io) => io.sectionId === sectionId && io.itemId === itemId
      );
      const [removed] = newItemOrder.splice(fromIndex, 1);
      newItemOrder.splice(toIndex, 0, removed);
      setItemOrder(newItemOrder);

      // If dragging within the same section
      if (draggedSection === sectionId) {
        const items = [...newSectionsState[sectionId].items];
        const drgIndex = items.findIndex(
          (it) => it.id === draggedSubItem.itemId
        );
        const dropIndex = items.findIndex((it) => it.id === itemId);

        items.splice(drgIndex, 1);
        items.splice(dropIndex, 0, draggedItemObj);

        newSectionsState[sectionId].items = items;
        setSectionsState(newSectionsState);
      } else {
        // Dragging between different sections
        const sourceItems = [...newSectionsState[draggedSection].items];
        const targetItems = [...newSectionsState[sectionId].items];

        const drgIndex = sourceItems.findIndex(
          (it) => it.id === draggedSubItem.itemId
        );
        const dropIndex = targetItems.findIndex((it) => it.id === itemId);

        sourceItems.splice(drgIndex, 1);
        targetItems.splice(dropIndex, 0, draggedItemObj);

        newSectionsState[draggedSection].items = sourceItems;
        newSectionsState[sectionId].items = targetItems;
        setSectionsState(newSectionsState);
      }
    }
    // SECTION Drag
    else if (draggedItem && !itemId) {
      if (draggedItem === sectionId) return;

      const newOrder = [...sectionOrder];
      const fromIndex = newOrder.indexOf(draggedItem);
      const toIndex = newOrder.indexOf(sectionId);

      newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, draggedItem);
      setSectionOrder(newOrder);

      // Also reorder itemOrder for that entire section
      const newItemOrder = [...itemOrder];
      // Filter items for the dragged section
      const draggedSectionItems = newItemOrder.filter(
        (io) => io.sectionId === draggedItem
      );
      // Remaining items
      const remaining = newItemOrder.filter(
        (io) => io.sectionId !== draggedItem
      );

      // Insert them before the first item of the target section
      const insertionIndex = remaining.findIndex(
        (io) => io.sectionId === sectionId
      );

      // If the target section isn't found, push them to the end
      if (insertionIndex >= 0) {
        remaining.splice(insertionIndex, 0, ...draggedSectionItems);
      } else {
        remaining.push(...draggedSectionItems);
      }

      setItemOrder(remaining);
    }
  };

  /**
   * handleDragEnd
   * We finalize the new order and persist to Redux + backend.
   */
  const handleDragEnd = async () => {
    setDraggedItem(null);
    setDraggedSubItem(null);

    // 1. Build new JSON
    const newJsonData = buildProposalJson({
      sectionsState,
      sectionOrder,
      itemOrder,
      selectedItems,
    });

    // 2. Dispatch to Redux, storing under 'ordering'
    // dispatch(
    //   updateProposalData({
    //     ...proposal_data,
    //     ordering: newJsonData,
    //   })
    // );

    const newProposalData = cloneDeep(proposal_data);

    // 2) modify clone
    newProposalData.ordering = newJsonData;

    // 3) dispatch
    dispatch(updateProposalData(newProposalData));

    // (We won't set isDirty to false here, because we haven't saved to server yet.)

    // 3. Persist to backend
    try {
      await updateProposal({
        id: proposalId,
        proposal_data: {
          ...proposal_data,
          ordering: newJsonData,
        },
      });
    } catch (error) {
      console.error("Error updating proposal:", error);
    }
  };

  // ---- Checkbox Selection Handling ----
  const handleCheckboxChange = async (sectionId, itemId, checked) => {
    setIsDirty(true);

    const updatedSelectedItems = {
      ...selectedItems,
      [`${sectionId}-${itemId}`]: checked,
    };
    setSelectedItems(updatedSelectedItems);

    // Build new JSON
    const newJsonData = buildProposalJson({
      sectionsState,
      sectionOrder,
      itemOrder,
      selectedItems: updatedSelectedItems,
    });

    // Dispatch & optionally persist to server
    // (you can decide if you want to persist immediately or wait for user to press save)
    // dispatch(
    //   updateProposalData({
    //     ...proposal_data,
    //     ordering: newJsonData,
    //   })
    // );

    const newProposalData = cloneDeep(proposal_data);

    // 2) modify clone
    newProposalData.ordering = newJsonData;

    // 3) dispatch
    dispatch(updateProposalData(newProposalData));
    try {
      await updateProposal({
        id: proposalId,
        proposal_data: {
          ...proposal_data,
          ordering: newJsonData,
        },
      });
    } catch (error) {
      console.error("Error updating proposal:", error);
    }
  };

  // ---- Render ----
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        height: "100vh",
        mx: "auto",
        p: 2,
        marginTop: "64px",
        overflowY: "auto", // Make content scrollable
        position: "relative",
        zIndex: 0,
      }}
    >
      <Dialog open={showDialog} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelNavigation}>Stay</Button>
          {/* <Button onClick={confirmNavigation} color="error">
            Leave
          </Button> */}
        </DialogActions>
      </Dialog>

      <Navbar />

      {/* Left Panel - Builder Interface */}
      <Card
        sx={{
          width: showPreview ? "50%" : "100%",
          transition: "width 0.3s",
          height: "fit-content",
        }}
      >
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5" component="h2">
                  Proposal Builder
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  XYZ Proposal - Draft
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={handleSaveDraft}
                  size="small"
                  // Toggle color based on isDirty
                  color={isDirty ? "error" : "primary"}
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    // Optionally, override or refine the styling:
                    // color: isDirty ? "error.main" : "primary.main",
                    // borderColor: isDirty ? "error.main" : "primary.main",
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setShowPreview(!showPreview)}
                  startIcon={showPreview ? <EyeIcon /> : <EyeOffIcon />}
                  size="small"
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    bgcolor: showPreview ? "grey.100" : "transparent",
                    "&:hover": {
                      bgcolor: showPreview ? "grey.200" : "grey.50",
                    },
                  }}
                >
                  {showPreview ? "Hide Preview" : "Show Preview"}
                </Button>
              </Box>
            </Box>
          }
        />
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <TextField
                fullWidth
                placeholder="Proposal Name"
                defaultValue="XYZ Proposal"
                size="small"
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {sectionOrder.map((sectionKey) => {
              const section = sectionsState[sectionKey];
              if (!section) return null;

              const IconComponent = getIconComponent(sectionKey);
              return (
                <Paper
                  key={sectionKey}
                  elevation={1}
                  draggable
                  onDragStart={(e) => handleDragStart(e, sectionKey)}
                  onDragOver={(e) => handleDragOver(e, sectionKey)}
                  onDragEnd={handleDragEnd}
                >
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "grey.50",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "grey.100" },
                    }}
                    onClick={() => toggleSection(sectionKey)}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <GripVerticalIcon
                        sx={{ color: "text.secondary", cursor: "move" }}
                      />
                      <IconComponent />
                      <Typography variant="subtitle1">
                        {section.title}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        {section.items.length} items
                      </Typography>
                      <Typography color="text.secondary">
                        {expandedSections[sectionKey] ? "−" : "+"}
                      </Typography>
                    </Box>
                  </Box>

                  {expandedSections[sectionKey] && (
                    <Box sx={{ p: 2 }}>
                      {section.items.map((item) => (
                        <Paper
                          key={item.id}
                          variant="outlined"
                          draggable
                          onDragStart={(e) =>
                            handleDragStart(e, sectionKey, item.id)
                          }
                          onDragOver={(e) =>
                            handleDragOver(e, sectionKey, item.id)
                          }
                          onDragEnd={handleDragEnd}
                          sx={{
                            p: 1.5,
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            "&:hover": { borderColor: "primary.main" },
                          }}
                        >
                          <GripVerticalIcon
                            sx={{
                              color: "text.secondary",
                              cursor: "move",
                              fontSize: "1.2rem",
                            }}
                          />
                          <Checkbox
                            checked={
                              selectedItems[`${sectionKey}-${item.id}`] || false
                            }
                            onChange={(e) => {
                              handleCheckboxChange(
                                sectionKey,
                                item.id,
                                e.target.checked
                              );
                            }}
                            size="small"
                          />
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle2">
                              {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.description}
                            </Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenDialog(sectionKey, item.id)
                            }
                          >
                            <SettingsIcon fontSize="small" />
                          </IconButton>
                        </Paper>
                      ))}

                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{ mt: 1 }}
                      >
                        Add Item
                      </Button>
                    </Box>
                  )}
                </Paper>
              );
            })}
          </Box>
        </CardContent>
      </Card>

      {/* Right Panel - Preview */}
      {showPreview && (
        <Card
          sx={{
            width: "50%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Preview</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button size="small" sx={{ color: "primary.main" }}>
                    Share
                  </Button>
                </Box>
              </Box>
            }
          />
          <CardContent
            sx={{
              flex: 1,
              p: "16px !important",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {Object.keys(selectedItems).length === 0 ? (
              <Typography variant="body2" align="center" color="text.secondary">
                Select items to preview them here
              </Typography>
            ) : (
              <Box sx={{ flex: 1, minHeight: 0 }}>
                <PDFViewer
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                >
                  <PDFDocument
                    itemOrder={itemOrder}
                    selectedItems={selectedItems}
                    sectionsState={sectionsState}
                    sectionOrder={sectionOrder}
                    getEditor={getEditor}
                  />
                </PDFViewer>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {/* Settings Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height: "90vh",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {selectedSection &&
              getEditor(
                selectedSection.sectionId,
                selectedSection.itemId,
                proposal_data
              )?.title}
          </Typography>
          <IconButton onClick={handleCloseDialog} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {selectedSection &&
            (() => {
              const editorData = getEditor(
                selectedSection.sectionId,
                selectedSection.itemId,
                proposal_data
              );
              if (!editorData)
                return (
                  <Typography>No editor available for this section</Typography>
                );

              const EditorComponent = editorData.component;

              // If "financial > costs" section, store cost data in ordering
              if (
                selectedSection.sectionId === "financial" &&
                selectedSection.itemId === "costs"
              ) {
                return (
                  <EditorComponent
                    takeOffData={editorData.data}
                    onSave={async (data) => {
                      const newProposalData = cloneDeep(proposal_data);
                      newProposalData.settings = data;
                      dispatch(updateProposalData(newProposalData));

                      await updateProposal({
                        id: proposalId,
                        proposal_data: newProposalData,
                      });

                      handleCloseDialog();
                    }}
                  />
                );
              }

              // Otherwise, just an example of generic usage
              return (
                <EditorComponent
                  onSave={async (data) => {
                    const newOrdering = {
                      ...(proposal_data.ordering || {}),
                      [selectedSection.itemId]: data,
                    };

                    // dispatch(
                    //   updateProposalData({
                    //     ...proposal_data,
                    //     ordering: newOrdering,
                    //   })
                    // );

                    const newProposalData = cloneDeep(proposal_data);

                    // 2) modify clone
                    newProposalData.ordering = newOrdering;

                    // 3) dispatch
                    dispatch(updateProposalData(newProposalData));

                    await updateProposal({
                      id: proposalId,
                      proposal_data: newProposalData,
                    });
                    handleCloseDialog();
                  }}
                />
              );
            })()}
        </DialogContent>
      </Dialog>

      {/* Snackbar / Toast for a successful save */}
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowToast(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Draft saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProposalBuilderInterface;
