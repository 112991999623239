// components/Profile.js

import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  CircularProgress,
  Divider, // Import Divider for better UI separation
} from '@mui/material';
import { AuthContext } from '../../context/authContext';
import MainLayout from '../MainLayout';
import { inviteUser, fetchUsers, getIsAdmin, getCurrentUser, updateUserRole } from '../../services/userService'; // Import updateUserRole
import { CheckCircleOutline } from '@mui/icons-material';
import { permissionsDescriptions, rolePermissionsDisplay } from '../../constants/permissions';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // Local states for current user details
  const [currentUser, setCurrentUser] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const [rolePermissions, setRolePermissions] = useState({});
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('Estimator');
  const roles = ['Administrator', 'Project Manager', 'Estimator'];

  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [loadingAdminStatus, setLoadingAdminStatus] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  // Fetch current user details after ensuring user is authenticated
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!isAuthenticated) return;
      setLoadingCurrentUser(true);
      setErrorMessage('');
      try {
        const userData = await getCurrentUser();
        setCurrentUser(userData);
        setUsername(userData.username || '');
        setEmail(userData.email || '');
      } catch (error) {
        setErrorMessage('Failed to load user details. Please refresh or contact support.');
      } finally {
        setLoadingCurrentUser(false);
      }
    };
    fetchUserDetails();
  }, [isAuthenticated]);

  // After we have currentUser, check admin status and possibly fetch users
  useEffect(() => {
    const checkAdminAndFetchUsers = async () => {
      if (!currentUser) return;
      setLoadingAdminStatus(true);
      try {
        const { is_admin } = await getIsAdmin();
        setIsAdmin(is_admin);

        if (is_admin) {
          await handleFetchUsers();
        }
      } catch (error) {
        setErrorMessage('Failed to determine admin status. Please try again later.');
      } finally {
        setLoadingAdminStatus(false);
      }
    };

    checkAdminAndFetchUsers();
  }, [currentUser]);

  const handleFetchUsers = async () => {
    setLoadingUsers(true);
    setErrorMessage('');
    try {
      const response = await fetchUsers();
      setAvailableUsers(response.users);

      const rolesMap = {};
      const permissionsMap = {};
      response.users.forEach((userItem) => {
        const roleName = userItem.roles[0]; // Assuming single role per user
        rolesMap[userItem.email] = roleName;
        permissionsMap[userItem.email] = userItem.permissions;
      });
      setUserRoles(rolesMap);
      setRolePermissions(permissionsMap);
    } catch (err) {
      setErrorMessage('Error fetching users. Please try again later.');
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleUserRoleChange = async (email, role) => {
    setUserRoles((prev) => ({
      ...prev,
      [email]: role,
    }));

    // Implement role update API call for persisting changes
    try {
      await updateUserRole(email, role); // Ensure you have this service function
      // Optionally, refetch users to get updated permissions
      await handleFetchUsers();
    } catch (error) {
      setErrorMessage('Error updating user role. Please try again later.');
      // Revert role change in state if API call fails
      setUserRoles((prev) => ({
        ...prev,
        [email]: roles.find((r) => rolePermissionsDisplay[r]?.includes(role)) || 'Estimator',
      }));
    }
  };

  const [successMessage, setSuccessMessage] = useState('');

  const handleCreateUser = async () => {
    if (!newUserEmail.trim()) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    if (!roles.includes(newUserRole)) {
      setErrorMessage('Invalid role selected.');
      return;
    }
    setErrorMessage('');
    setSuccessMessage(''); // Reset success message
    try {
      const response = await inviteUser(newUserEmail, newUserRole);
      if (response && response.message === 'Invitation sent successfully') {
        setSuccessMessage('Invitation sent successfully!');
      }
      setNewUserEmail('');
      setNewUserRole('Estimator');
      if (isAdmin) await handleFetchUsers();
    } catch (error) {
      setErrorMessage('Error inviting user. Please try again later.');
    }
  };
  

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    // Implement profile update logic here if needed
    console.log('Profile update submitted:', { username, email, password, confirmPassword });
  };

  // Loading states:
  if (!isAuthenticated) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h6">You must be logged in to view this page.</Typography>
        </Box>
      </MainLayout>
    );
  }

  if (loadingCurrentUser) {
    return (
      <MainLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <CircularProgress />
        </Box>
      </MainLayout>
    );
  }

  if (!currentUser) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h6" color="error">
            {errorMessage || 'Failed to load user details.'}
          </Typography>
        </Box>
      </MainLayout>
    );
  }

  // Helper function to get permissions for a role
  const getPermissionsForRole = (role) => {
    return rolePermissionsDisplay[role] || [];
  };

  // Once currentUser is loaded, we can show the UI
  return (
    <MainLayout>
      <Box sx={{ maxWidth: '1200px', margin: 'auto', padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Profile Settings
        </Typography>

        {errorMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </Paper>
        )}

        {/* Profile Section */}
        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <form onSubmit={handleProfileSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Avatar src={profileImage} sx={{ width: 80, height: 80, marginRight: 2 }} />
                <Button variant="outlined" component="label">
                  Upload Profile Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => setProfileImage(URL.createObjectURL(e.target.files[0]))}
                  />
                </Button>
              </Box>
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                type="email"
              />
              <TextField
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <TextField
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Save Changes
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* User Management Section */}
        {loadingAdminStatus ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          isAdmin && (
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  User Management
                </Typography>

                {successMessage && (
                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography variant="body1" style={{ color: 'green' }}>
                    {successMessage}
                    </Typography>
                </Paper>
                )}

                {/* Create New User */}
                <Typography variant="h6">Invite New User</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2, flexWrap: 'wrap' }}>
                  <TextField
                    label="User Email"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    variant="outlined"
                    sx={{ flex: '1 1 250px' }}
                  />
                  <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      value={newUserRole}
                      onChange={(e) => setNewUserRole(e.target.value)}
                      label="Role"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button variant="contained" color="primary" onClick={handleCreateUser}>
                    Invite User
                  </Button>
                </Box>

                {/* Display Permissions for Selected Role */}
                <Box sx={{ marginBottom: 4 }}>
                  <Typography variant="subtitle1">Selected Role Permissions:</Typography>
                  <List dense>
                    {getPermissionsForRole(newUserRole).length > 0 ? (
                      getPermissionsForRole(newUserRole).map((permName) => (
                        <ListItem key={permName}>
                          <ListItemIcon>
                            <CheckCircleOutline color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={permissionsDescriptions[permName]} />
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2">No permissions assigned.</Typography>
                    )}
                  </List>
                </Box>

                {/* Divider for better separation */}
                <Divider sx={{ marginY: 4 }} />

                {loadingUsers ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ mt: 4, marginBottom: 2 }}>
                      Manage User Roles
                    </Typography>
                    {availableUsers.length === 0 ? (
                      <Typography variant="body1">No users found in your company.</Typography>
                    ) : (
                      availableUsers.map((userItem) => (
                        <Paper key={userItem.email} sx={{ padding: 2, marginBottom: 2 }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Typography variant="subtitle1">{userItem.email}</Typography>
                              <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                  value={userRoles[userItem.email] || 'Estimator'}
                                  onChange={(e) => handleUserRoleChange(userItem.email, e.target.value)}
                                  label="Role"
                                >
                                  {roles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                      {role}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Typography variant="subtitle2">Permissions:</Typography>
                              <List dense>
                                {getPermissionsForRole(userRoles[userItem.email]).length > 0 ? (
                                  getPermissionsForRole(userRoles[userItem.email]).map((permName) => (
                                    <ListItem key={permName}>
                                      <ListItemIcon>
                                        <CheckCircleOutline color="primary" />
                                      </ListItemIcon>
                                      <ListItemText primary={permissionsDescriptions[permName]} />
                                    </ListItem>
                                  ))
                                ) : (
                                  <Typography variant="body2">No permissions assigned.</Typography>
                                )}
                              </List>
                            </Grid>
                          </Grid>
                        </Paper>
                      ))
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          )
        )}

      </Box>
    </MainLayout>
  );
}

export default Profile;
