import apiAxios from './axiosConfig';

export const getCurrentUser = async () => {
  try {
    const response = await apiAxios.get('/user/me');
    return response.data;
  } catch (error) {
    console.error('Error fetching current user details:', error.response?.data);
    throw error;
  }
};

export const getIsAdmin = async () => {
  try {
    const response = await apiAxios.get('/user/is_admin');
    return response.data;
  } catch (error) {
    console.error('Error checking admin status:', error.response?.data);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await apiAxios.get('/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error.response?.data);
    throw error;
  }
};

export const inviteUser = async (email, role) => {
  try {
    const response = await apiAxios.post('/users/invite', { email, role });
    return response.data;
  } catch (error) {
    console.error('Error inviting user:', error.response?.data);
    throw error;
  }
};

// New service function to update user role
export const updateUserRole = async (email, role) => {
    try {
      const response = await apiAxios.put(`/users/${email}/role`, { role });
      return response.data;
    } catch (error) {
      console.error('Error updating user role:', error.response?.data);
      throw error;
    }
  };